import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getMilestone } from 'src/service/MilestoneService'

export const useMilestone = (id: number) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['milestone', projectId, id],
    queryFn: () => getMilestone(projectId, id),
  })
}
