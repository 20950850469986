import { keepPreviousData, useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import {
  getFileContainerSystems,
  getSystems,
  getSystemsWithoutPage,
  getSystemsWithPage,
  getSystem,
  getSystemByRecordId,
} from 'src/service/SystemService'

export const queryKeySystemListFiltered = 'systemListFiltered'

export const useSystemsByIds = (
  ids: number[],
  options?: {
    enabled?: boolean
  },
) => {
  const projectId = useProjectId()
  return useQuery({
    ...options,
    queryKey: ['systems', projectId, ...ids],
    queryFn: () =>
      ids.length
        ? getSystemsWithoutPage(projectId, { ids })
        : Promise.resolve([]),
  })
}

export const usePaginatedSystemByIds = (
  ids: number[],
  per_page: number,
  page: number,
) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['systems', projectId, { ids }, per_page, page],
    queryFn: () => getSystemsWithPage(projectId, page, per_page, { ids }),
  })
}

export const useSystem = (id?: number) => {
  return useQuery({
    enabled: !!id,
    queryKey: ['systems', id],
    queryFn: () => getSystem(id!),
  })
}

export const useSystemByRecordId = (projectId: number, recordId?: string) => {
  return useQuery({
    enabled: !!recordId,
    queryKey: ['systems', projectId, recordId],
    queryFn: () => getSystemByRecordId(projectId, recordId!),
  })
}

export const useProjectSystems = (options?: { enabled?: boolean }) => {
  const projectId = useProjectId()
  return useQuery({
    ...options,
    queryKey: ['systems', projectId],
    queryFn: () => getSystems(projectId),
  })
}

export const useFileContainerSystems = (fileContainerId: number) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['systems', 'fileContainer', projectId, fileContainerId],
    queryFn: () => getFileContainerSystems(projectId, fileContainerId),
  })
}

export const useSystemListFiltered = ({
  sort,
  filters,
  page,
  pageSize,
  enabled = true,
}: {
  sort: Parameters<typeof getSystemsWithPage>[3]['sort']
  filters: Omit<Parameters<typeof getSystemsWithPage>[3], 'sort'>
  page: number
  pageSize: number
  enabled?: boolean
}) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [
      queryKeySystemListFiltered,
      { projectId: projectId, filters, sort, page, pageSize },
    ],
    queryFn: () =>
      getSystemsWithPage(projectId, page, pageSize, { ...filters, sort }),
    placeholderData: keepPreviousData,
    enabled,
  })
}
