import { useContext, useEffect, useRef, useState } from 'react'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import {
  contractFilterService,
  controlAreaFilterService,
  controlAreaGroupFilterService,
  deliveryFilterService,
  disciplineFilterService,
  documentTypeFilterService,
  floorsFilterService,
  folderFilterService,
  improvementFilterService,
  keypointFilterSercice,
  milestoneFilterService,
  phaseFilterSercice,
  processFilterService,
  riskAreaFilterService,
  riskFilterService,
  riskGroupFilterService,
  roleFilterService,
  roomFilterService,
  sectionsFilterService,
  statusFilterService,
  systemFilterService,
  systemFmiFilterService,
  systemFmiGroupFilterService,
  systemMmiFilterService,
  systemTypeFilterService,
  systemTypeGroupFilterService,
  systemTypeUniqueFilterService,
  tagsFilterService,
  taskTypesFilterService,
  testFilterService,
  testSystemGroupFilterService,
  testTypeFilterService,
  testWorkGroupFilterService,
  theamFilterService,
  trainsFilterService,
  trainTypesFilterService,
  userFilterServices,
  wagonsFilterService,
  wagonTypesFilterService,
} from 'src/service/FilterServices'
import { ISingleFilter } from './ListContextProvider'

const useListHelper = () => {
  const projectContext = useContext(ProjectContext)
  const [projectId, setProjectId] = useState<number>(
    projectContext.state.currentProject.id,
  )

  const [objectTypeSyntaxFilter, setObjectTypeSyntaxFilter] = useState<
    ISingleFilter[]
  >([])
  const [testTypeFilter, setTesttypeFilter] = useState<ISingleFilter[]>([])
  const [systemMmiFilter, setSystemMmiFilter] = useState<ISingleFilter[]>([])
  const [systemFmiFilter, setSystemFmiFilter] = useState<ISingleFilter[]>([])
  const [systemFmiGroupFilter, setSystemFmiGroupFilter] = useState<
    ISingleFilter[]
  >([])
  const [systemTypeFilter, setSystemTypeFilter] = useState<ISingleFilter[]>([])
  const [systemTypeUniqueFilter, setSystemTypeUniqueFilter] = useState<
    ISingleFilter[]
  >([])
  const [systemTypeGroupFilter, setSystemTypeGroupFilter] = useState<
    ISingleFilter[]
  >([])
  const [folderFilter, setFolderFilter] = useState<ISingleFilter[]>([])
  const [statusFilter, setStatusFilter] = useState<ISingleFilter[]>([])
  const [testSystemGroupFilter, setTestSystemGroupFilter] = useState<
    ISingleFilter[]
  >([])
  const [testWorkGroupFilter, setTestWorkGroupFilter] = useState<
    ISingleFilter[]
  >([])
  const [themeFilter, setTheamFilter] = useState<ISingleFilter[]>([])
  const [userFilter, setUserFilter] = useState<ISingleFilter[]>([])
  const [meetingLeaderFilter, setMeetingLeaderFilter] = useState<
    ISingleFilter[]
  >([])
  const [meetingRecorderFilter, setMeetingRecorderFilter] = useState<
    ISingleFilter[]
  >([])
  const [keypointFilter, setKeypointFilter] = useState<ISingleFilter[]>([])
  const [disciplineFilter, setDisciplineFilter] = useState<ISingleFilter[]>([])
  const [contractFilter, setContractFilter] = useState<ISingleFilter[]>([])
  const [processFilter, setProcessFilter] = useState<ISingleFilter[]>([])
  const [phasesFilter, setPhasesFilter] = useState<ISingleFilter[]>([])
  const [roleFilter, setRoleFilter] = useState<ISingleFilter[]>([])
  const [reporterFilter, setReporterFilter] = useState<ISingleFilter[]>([])
  const [milestoneFilter, setMilestoneFilter] = useState<ISingleFilter[]>([])
  const [riskGroupFilter, setRiskGroupFilter] = useState<ISingleFilter[]>([])
  const [riskFilter, setRiskFilter] = useState<ISingleFilter[]>([])
  const [riskAreaFilter, setRiskAreaFilter] = useState<ISingleFilter[]>([])
  const [testFilter, setTestFilter] = useState<ISingleFilter[]>([])
  const [sectionFilter, setSectionFilter] = useState<ISingleFilter[]>([])
  const [floorFilter, setFloorFilter] = useState<ISingleFilter[]>([])
  const [trainFilter, setTrainFilter] = useState<ISingleFilter[]>([])
  const [trainTypeFilter, setTrainTypeFilter] = useState<ISingleFilter[]>([])
  const [wagonFilter, setWagonFilter] = useState<ISingleFilter[]>([])
  const [wagonTypeFilter, setWagonTypeFilter] = useState<ISingleFilter[]>([])
  const [deliveryFilter, setDeliveryFilter] = useState<ISingleFilter[]>([])
  const [tagFilter, setTagFilter] = useState<ISingleFilter[]>([])
  const [taskTypeFilter, setTaskTypeFilter] = useState<ISingleFilter[]>([])
  const [controlAreaGroupFilter, setControlAreaGroupFilter] = useState<
    ISingleFilter[]
  >([])
  const [controlAreaFilter, setControlAreaFilter] = useState<ISingleFilter[]>(
    [],
  )
  const [improvementFilter, setImprovementFilter] = useState<ISingleFilter[]>(
    [],
  )
  const [systemFilter, setSystemFilter] = useState<ISingleFilter[]>([])
  const [roomFilter, setRoomFilter] = useState<ISingleFilter[]>([])
  const [documentTypeFilter, setDocumentTypeFilter] = useState<ISingleFilter[]>(
    [],
  )

  const objectTypeSyntaxFilterRef = useRef(objectTypeSyntaxFilter)
  const themeFilterRef = useRef(themeFilter)
  const userFilterRef = useRef(userFilter)
  const meetingLeaderFilterRef = useRef(meetingLeaderFilter)
  const meetingRecorderFilterRef = useRef(meetingRecorderFilter)
  const keypointFilterRef = useRef(keypointFilter)
  const disciplineFilterRef = useRef(disciplineFilter)
  const contractFilterRef = useRef(contractFilter)
  const processFilterRef = useRef(processFilter)
  const phaseFilterRef = useRef(phasesFilter)
  const roleFilterRef = useRef(roleFilter)
  const reporterFilterRef = useRef(reporterFilter)
  const milestoneFilterRef = useRef(milestoneFilter)
  const testFilterRef = useRef(testFilter)
  const testTypeFilterRef = useRef(testTypeFilter)
  const systemMmiFilterRef = useRef(systemMmiFilter)
  const systemFmiFilterRef = useRef(systemFmiFilter)
  const systemFmiGroupFilterRef = useRef(systemFmiGroupFilter)
  const systemTypeFilterRef = useRef(systemTypeFilter)
  const systemTypeUniqueFilterRef = useRef(systemTypeUniqueFilter)
  const systemTypeGroupFilterRef = useRef(systemTypeGroupFilter)
  const folderFilterRef = useRef(folderFilter)
  const statusFilterRef = useRef(statusFilter)
  const testSystemGroupFilterRef = useRef(testSystemGroupFilter)
  const testWorkGroupFilterRef = useRef(testWorkGroupFilter)
  const riskGroupFilterRef = useRef(riskGroupFilter)
  const riskFilterRef = useRef(riskFilter)
  const riskAreaFilterRef = useRef(riskAreaFilter)
  const sectionFilterRef = useRef(sectionFilter)
  const floorFilterRef = useRef(floorFilter)
  const trainFilterRef = useRef(trainFilter)
  const trainTypeFilterRef = useRef(trainTypeFilter)
  const wagonFilterRef = useRef(wagonFilter)
  const wagonTypeFilterRef = useRef(wagonTypeFilter)
  const deliveryFilterRef = useRef(deliveryFilter)
  const tagFilterRef = useRef(tagFilter)
  const taskTypeFilterRef = useRef(taskTypeFilter)
  const controlAreaGroupFilterRef = useRef(controlAreaGroupFilter)
  const controlAreaFilterRef = useRef(controlAreaFilter)
  const improvementFilterRef = useRef(improvementFilter)
  const systemFilterRef = useRef(systemFilter)
  const roomFilterRef = useRef(roomFilter)
  const documentTypeFilterRef = useRef(documentTypeFilter)

  useEffect(() => {
    objectTypeSyntaxFilterRef.current = objectTypeSyntaxFilter
    themeFilterRef.current = themeFilter
    userFilterRef.current = userFilter
    disciplineFilterRef.current = disciplineFilter
    processFilterRef.current = processFilter
    phaseFilterRef.current = phasesFilter
    contractFilterRef.current = contractFilter
    milestoneFilterRef.current = milestoneFilter
    keypointFilterRef.current = keypointFilter
    reporterFilterRef.current = reporterFilter
    roleFilterRef.current = roleFilter
    testFilterRef.current = testFilter
    testTypeFilterRef.current = testTypeFilter
    systemMmiFilterRef.current = systemMmiFilter
    systemFmiFilterRef.current = systemFmiFilter
    systemFmiGroupFilterRef.current = systemFmiGroupFilter
    systemTypeFilterRef.current = systemTypeFilter
    systemTypeUniqueFilterRef.current = systemTypeUniqueFilter
    systemTypeGroupFilterRef.current = systemTypeGroupFilter
    statusFilterRef.current = statusFilter
    testSystemGroupFilterRef.current = testSystemGroupFilter
    testWorkGroupFilterRef.current = testWorkGroupFilter
    sectionFilterRef.current = sectionFilter
    floorFilterRef.current = floorFilter
    trainFilterRef.current = trainFilter
    trainTypeFilterRef.current = trainTypeFilter
    wagonFilterRef.current = wagonFilter
    wagonTypeFilterRef.current = wagonTypeFilter
    deliveryFilterRef.current = deliveryFilter
    tagFilterRef.current = tagFilter
    taskTypeFilterRef.current = taskTypeFilter
    controlAreaGroupFilterRef.current = controlAreaGroupFilter
    controlAreaFilterRef.current = controlAreaFilter
    improvementFilterRef.current = improvementFilter
    systemFilterRef.current = systemFilter
    roomFilterRef.current = roomFilter
    documentTypeFilterRef.current = documentTypeFilter
  })

  useEffect(() => {
    setProjectId(projectContext.state.currentProject.id)
    setObjectTypeSyntaxFilter([])
    setTheamFilter([])
    setUserFilter([])
    setDisciplineFilter([])
    setKeypointFilter([])
    setContractFilter([])
    setProcessFilter([])
    setPhasesFilter([])
    setRoleFilter([])
    setMilestoneFilter([])
    setTestFilter([])
    setTesttypeFilter([])
    setSystemMmiFilter([])
    setSystemFmiFilter([])
    setSystemFmiGroupFilter([])
    setTestSystemGroupFilter([])
    setTestWorkGroupFilter([])
    setSectionFilter([])
    setFloorFilter([])
    setTrainFilter([])
    setWagonFilter([])
    setDeliveryFilter([])
    setTagFilter([])
    setTaskTypeFilter([])
    setControlAreaGroupFilter([])
    setControlAreaFilter([])
    setImprovementFilter([])
    setSystemFilter([])
    setSystemTypeFilter([])
    setSystemTypeGroupFilter([])
    setFolderFilter([])
    setRoomFilter([])
    setDocumentTypeFilter([])
  }, [projectContext])

  const getThemeFilter = async (): Promise<ISingleFilter[]> => {
    if (themeFilterRef.current.length === 0) {
      const theams = await theamFilterService(projectId)
      setTheamFilter(theams)
      return theams
    }
    return themeFilterRef.current
  }

  const getUserFilter = async (): Promise<ISingleFilter[]> => {
    if (userFilterRef.current.length === 0) {
      const user = await userFilterServices(projectId)
      setUserFilter(user)
      return user
    }
    return userFilterRef.current
  }

  const getKeypointFilter = async (): Promise<ISingleFilter[]> => {
    if (keypointFilterRef.current.length === 0) {
      const keypoints = await keypointFilterSercice(projectId)
      setKeypointFilter(keypoints)
      return keypoints
    }
    return keypointFilterRef.current
  }

  const getDisciplineFilter = async (): Promise<ISingleFilter[]> => {
    if (disciplineFilterRef.current.length === 0) {
      const discplines = await disciplineFilterService(projectId)
      setDisciplineFilter(discplines)
      return discplines
    }
    return disciplineFilterRef.current
  }

  const getContractFilter = async (): Promise<ISingleFilter[]> => {
    if (contractFilterRef.current.length === 0) {
      const contracts = await contractFilterService(projectId)
      setContractFilter(contracts)
      return contracts
    }
    return contractFilterRef.current
  }

  const getProcessFilter = async (): Promise<ISingleFilter[]> => {
    if (processFilterRef.current.length === 0) {
      const process = await processFilterService(projectId)
      setProcessFilter(process)
      return process
    }
    return processFilterRef.current
  }

  const getPhasesFilter = async (): Promise<ISingleFilter[]> => {
    if (phaseFilterRef.current.length === 0) {
      const phases = await phaseFilterSercice(projectId)
      setPhasesFilter(phases)
      return phases
    }
    return phaseFilterRef.current
  }

  const getRoleFilter = async (): Promise<ISingleFilter[]> => {
    if (roleFilterRef.current.length === 0) {
      const roles = await roleFilterService(projectId)
      setRoleFilter(roles)
      return roles
    }
    return roleFilterRef.current
  }

  const getUserFilterWithReporter = async (): Promise<ISingleFilter[]> => {
    if (userFilterRef.current.length === 0) {
      const user = await userFilterServices(projectId)
      setUserFilter(user)
      return user
    }
    return userFilterRef.current
  }

  const getReporterFilter = async (): Promise<ISingleFilter[]> => {
    if (reporterFilterRef.current.length === 0) {
      const user = await userFilterServices(projectId)
      setReporterFilter(user)
      return user
    }
    return reporterFilterRef.current
  }

  const getMeetingRecorderFilter = async (): Promise<ISingleFilter[]> => {
    if (meetingRecorderFilterRef.current.length === 0) {
      const user = await userFilterServices(projectId)
      setMeetingRecorderFilter(user)
      return user
    }
    return meetingRecorderFilterRef.current
  }

  const getMeetingLeaderFilter = async (): Promise<ISingleFilter[]> => {
    if (meetingLeaderFilterRef.current.length === 0) {
      const user = await userFilterServices(projectId)
      setMeetingLeaderFilter(user)
      return user
    }
    return meetingLeaderFilterRef.current
  }

  const getMilestoneFilter = async (): Promise<ISingleFilter[]> => {
    if (milestoneFilterRef.current.length === 0) {
      const milestones = await milestoneFilterService(projectId)
      setMilestoneFilter(milestones)
      return milestones
    }
    return milestoneFilterRef.current
  }

  const getRiskGroupFilter = async (): Promise<ISingleFilter[]> => {
    if (riskGroupFilterRef.current.length === 0) {
      const riskGroups = await riskGroupFilterService(projectId)
      setRiskGroupFilter(riskGroups)
      return riskGroups
    }
    return riskGroupFilterRef.current
  }

  const getRiskFilter = async (
    riskGroupId?: number,
  ): Promise<ISingleFilter[]> => {
    if (riskFilterRef.current.length === 0) {
      const risks = await riskFilterService(projectId, riskGroupId)
      setRiskFilter(risks)
      return risks
    }
    return riskFilterRef.current
  }

  const getRiskAreaFilter = async (): Promise<ISingleFilter[]> => {
    if (riskAreaFilterRef.current.length === 0) {
      const riskAreas = await riskAreaFilterService(projectId)
      setRiskAreaFilter(riskAreas)
      return riskAreas
    }
    return riskAreaFilterRef.current
  }

  const getTestFilter = async (): Promise<ISingleFilter[]> => {
    if (testFilterRef.current.length === 0) {
      const testType = await testFilterService(projectId)
      setTestFilter(testType)
      return testType
    }
    return testFilterRef.current
  }

  const getTestTypeFilter = async (): Promise<ISingleFilter[]> => {
    if (testTypeFilterRef.current.length === 0) {
      const testType = await testTypeFilterService(projectId)
      setTesttypeFilter(testType)
      return testType
    }
    return testTypeFilterRef.current
  }

  const getSystemMmiFilter = async (): Promise<ISingleFilter[]> => {
    if (systemMmiFilterRef.current.length === 0) {
      const systemMmi = await systemMmiFilterService(projectId)
      setSystemMmiFilter(systemMmi)
      return systemMmi
    }
    return systemMmiFilterRef.current
  }

  const getSystemFmiFilter = async (): Promise<ISingleFilter[]> => {
    if (systemFmiFilterRef.current.length === 0) {
      const systemFmi = await systemFmiFilterService(projectId)
      setSystemFmiFilter(systemFmi)
      return systemFmi
    }
    return systemFmiFilterRef.current
  }

  const getSystemFmiGroupFilter = async (): Promise<ISingleFilter[]> => {
    if (systemFmiGroupFilterRef.current.length === 0) {
      const systemFmiGroup = await systemFmiGroupFilterService(projectId)
      setSystemFmiGroupFilter(systemFmiGroup)
      return systemFmiGroup
    }
    return systemFmiGroupFilterRef.current
  }

  const getSystemTypeFilter = async (
    systemSettingId?: number,
  ): Promise<ISingleFilter[]> => {
    if (systemTypeFilterRef.current.length === 0) {
      const systemType = await systemTypeFilterService(
        projectId,
        systemSettingId,
      )
      setSystemTypeFilter(systemType)
      return systemType
    }
    return systemTypeFilterRef.current
  }

  const getSystemTypeUniqueFilter = async (): Promise<ISingleFilter[]> => {
    if (systemTypeUniqueFilterRef.current.length === 0) {
      const systemUniqueType = await systemTypeUniqueFilterService(projectId)
      setSystemTypeUniqueFilter(systemUniqueType)
      return systemUniqueType
    }
    return systemTypeUniqueFilterRef.current
  }

  const getSystemTypeGroupFilter = async (): Promise<ISingleFilter[]> => {
    if (systemTypeGroupFilterRef.current.length === 0) {
      const systemTypeGroup = await systemTypeGroupFilterService(projectId)
      setSystemTypeGroupFilter(systemTypeGroup)
      return systemTypeGroup
    }
    return systemTypeGroupFilterRef.current
  }

  const getFolderFilter = async (): Promise<ISingleFilter[]> => {
    if (folderFilterRef.current.length === 0) {
      const folders = await folderFilterService(projectId)
      setFolderFilter(folders)
      return folders
    }
    return systemTypeGroupFilterRef.current
  }

  const getSystemStatusFilter = async (): Promise<ISingleFilter[]> => {
    if (statusFilterRef.current.length === 0) {
      const status = await statusFilterService(projectId, 'System')
      setStatusFilter(status)
      return status
    }
    return statusFilterRef.current
  }

  const getTestSystemGroupStatusFilter = async (): Promise<ISingleFilter[]> => {
    if (statusFilterRef.current.length === 0) {
      const status = await statusFilterService(projectId, 'TestSystemGroup')
      setStatusFilter(status)
      return status
    }
    return statusFilterRef.current
  }

  const getTestWorkGroupStatusFilter = async (): Promise<ISingleFilter[]> => {
    if (statusFilterRef.current.length === 0) {
      const status = await statusFilterService(projectId, 'TestWorkGroup')
      setStatusFilter(status)
      return status
    }
    return statusFilterRef.current
  }

  const getTestSystemGroupFilter = async (): Promise<ISingleFilter[]> => {
    if (testSystemGroupFilterRef.current.length === 0) {
      const testSystemGroup = await testSystemGroupFilterService(projectId)
      setTestSystemGroupFilter(testSystemGroup)
      return testSystemGroup
    }
    return testSystemGroupFilterRef.current
  }

  const getTestWorkGroupFilter = async (): Promise<ISingleFilter[]> => {
    if (testWorkGroupFilterRef.current.length === 0) {
      const testWorkGroup = await testWorkGroupFilterService(projectId)
      setTestWorkGroupFilter(testWorkGroup)
      return testWorkGroup
    }
    return testWorkGroupFilterRef.current
  }

  const getSectionFilter = async (): Promise<ISingleFilter[]> => {
    if (sectionFilterRef.current.length === 0) {
      const sections = await sectionsFilterService(projectId)
      setSectionFilter(sections)
      return sections
    }
    return sectionFilterRef.current
  }

  const getFloorFilter = async (): Promise<ISingleFilter[]> => {
    if (floorFilterRef.current.length === 0) {
      const floors = await floorsFilterService(projectId)
      setFloorFilter(floors)
      return floors
    }
    return floorFilterRef.current
  }

  const getTrainFilter = async (): Promise<ISingleFilter[]> => {
    if (trainFilterRef.current.length === 0) {
      const trains = await trainsFilterService(projectId)
      setTrainFilter(trains)
      return trains
    }
    return trainFilterRef.current
  }

  const getTrainTypeFilter = async (): Promise<ISingleFilter[]> => {
    if (trainTypeFilterRef.current.length === 0) {
      const trainTypes = await trainTypesFilterService(projectId)
      setTrainTypeFilter(trainTypes)
      return trainTypes
    }
    return trainTypeFilterRef.current
  }

  const getWagonFilter = async (): Promise<ISingleFilter[]> => {
    if (wagonFilterRef.current.length === 0) {
      const wagons = await wagonsFilterService(projectId)
      setWagonFilter(wagons)
      return wagons
    }
    return wagonFilterRef.current
  }

  const getWagonTypeFilter = async (): Promise<ISingleFilter[]> => {
    if (wagonTypeFilterRef.current.length === 0) {
      const wagons = await wagonTypesFilterService(projectId)
      setWagonTypeFilter(wagons)
      return wagons
    }
    return wagonTypeFilterRef.current
  }

  const getDeliveryFilter = async (): Promise<ISingleFilter[]> => {
    if (deliveryFilterRef.current.length === 0) {
      const deliveries = await deliveryFilterService(projectId)
      setDeliveryFilter(deliveries)
      return deliveries
    }
    return deliveryFilterRef.current
  }

  const getTagFilter = async (): Promise<ISingleFilter[]> => {
    if (tagFilterRef.current.length === 0) {
      const tags = await tagsFilterService(projectId)
      setTagFilter(tags)
      return tags
    }
    return tagFilterRef.current
  }

  const getTaskTypeFilter = async (): Promise<ISingleFilter[]> => {
    if (taskTypeFilterRef.current.length === 0) {
      const taskTypes = await taskTypesFilterService(projectId)
      setTaskTypeFilter(taskTypes)
      return taskTypes
    }
    return taskTypeFilterRef.current
  }

  const getControlAreaGroupFilter = async (): Promise<ISingleFilter[]> => {
    if (controlAreaGroupFilterRef.current.length === 0) {
      const controlAreaGroups = await controlAreaGroupFilterService(projectId)
      setControlAreaGroupFilter(controlAreaGroups)
      return controlAreaGroups
    }
    return controlAreaGroupFilterRef.current
  }

  const getControlAreaFilter = async (): Promise<ISingleFilter[]> => {
    if (controlAreaFilterRef.current.length === 0) {
      const controlAreas = await controlAreaFilterService(projectId)
      setControlAreaFilter(controlAreas)
      return controlAreas
    }
    return controlAreaFilterRef.current
  }

  const getImprovementFilter = async (): Promise<ISingleFilter[]> => {
    if (improvementFilterRef.current.length === 0) {
      const improvements = await improvementFilterService(projectId)
      setImprovementFilter(improvements)
      return improvements
    }
    return improvementFilterRef.current
  }

  const getSystemFilter = async (): Promise<ISingleFilter[]> => {
    if (systemFilterRef.current.length === 0) {
      const systems = await systemFilterService(projectId)
      setSystemFilter(systems)
      return systems
    }
    return systemFilterRef.current
  }

  const getRoomFilter = async (): Promise<ISingleFilter[]> => {
    if (roomFilterRef.current.length === 0) {
      const rooms = await roomFilterService(projectId)
      setRoomFilter(rooms)
      return rooms
    }
    return roomFilterRef.current
  }

  const getDocumentTypeFilter = async (): Promise<ISingleFilter[]> => {
    if (documentTypeFilterRef.current.length === 0) {
      const documentTypes = await documentTypeFilterService()
      setDocumentTypeFilter(documentTypes)
      return documentTypes
    }
    return documentTypeFilterRef.current
  }

  return {
    getTeamFilter: getThemeFilter,
    getUserFilter,
    getKeypointFilter,
    getDisciplineFilter,
    getContractFilter,
    getProcessFilter,
    getPhasesFilter,
    getRoleFilter,
    getUserFilterWithReporter,
    getReporterFilter,
    getMeetingRecorderFilter,
    getMeetingLeaderFilter,
    getMilestoneFilter,
    getRiskGroupFilter,
    getRiskFilter,
    getRiskAreaFilter,
    getSectionFilter,
    getFloorFilter,
    getTrainFilter,
    getTrainTypeFilter,
    getWagonFilter,
    getWagonTypeFilter,
    getTestFilter,
    getTestTypeFilter,
    getSystemMmiFilter,
    getSystemFmiFilter,
    getSystemFmiGroupFilter,
    getSystemStatusFilter,
    getTestSystemGroupStatusFilter,
    getTestWorkGroupStatusFilter,
    getTestSystemGroupFilter,
    getTestWorkGroupFilter,
    getDeliveryFilter,
    getControlAreaGroupFilter,
    getControlAreaFilter,
    getTagFilter,
    getTaskTypeFilter,
    getImprovementFilter,
    getSystemFilter,
    getRoomFilter,
    getSystemTypeFilter,
    getSystemTypeUniqueFilter,
    getDocumentTypeFilter,
    getSystemTypeGroupFilter,
    getFolderFilter,
  }
}

export default useListHelper
