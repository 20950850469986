import 'moment/dist/locale/nb'
import '@taskctrl/react-calendar-timeline/lib/Timeline.css'
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TodayMarker,
} from '@taskctrl/react-calendar-timeline'
import moment from 'moment-timezone'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomMonthHeader from 'src/components/gantt/CustomMonthHeader'
import useDidMountEffect from 'src/components/hooks/UseDidMountEffect'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import {
  deleteMilestone,
  editMilestone,
  getMainprocessMilestoneCanvasData,
} from 'src/service/MilestoneService'
import {
  IMilestone,
  TimelineFilterTypes,
  IKeypoint,
  IMainProcess,
} from 'src/service/OrgTypes'
import Loader from 'src/ui-elements/loader/Loader'
import Modal from 'src/ui-elements/modal/Modal'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import { classNames } from '../../utility/utils'
import CanvasFloatingFilter, {
  CanvasFilterType,
} from '../canvas-filter/CanvasFloatingFilter'
import HelperIcons from '../canvas-header/HelperIcons'
import LockIcon from '../canvas-header/LockIcon'
import TimeLineResetDate from '../canvas-header/TimelineResetDate'
import ContextMenu, { IContextMenuItem } from '../context-menu/ContextMenu'
import DeleteModal from '../delete-modal/DeleteModal'
import KeypointModal from '../key-point/KeypointModal'
import MilestoneForm from '../milestones/MilestoneForm'
import MilestoneInspectorPanel from '../milestones/MilestoneInspectorPanel'
import MilestoneCardTimelineItem from './MilestoneCardTimelineItem'

const styleClass = {
  rowName: classNames(
    'bg-cool-gray-200',
    'flex',
    'items-center',
    'pl-2',
    'pr-2',
    'justify-between',
    'text-sm',
    'text-gray-700',
    'border-grey-root-3',
    'border-b-015',
  ),
  dateItem: classNames(
    'sticky',
    'px-4',
    'flex',
    'flex-col',
    'items-center',
    'justify-center',
    'font-bold',
    'bg-white',
    'border-r-01',
    'border-l-01',
    'border-b-04',
    'border-grey-root-3',
    '',
  ),
  sideHeader: classNames(
    'absolute',
    'flex',
    'flex-row',
    'left-header-border',
    'justify-center',
    'items-center',
    'pl-8 ',
    'bg-green-wash',
  ),
  gridItem: classNames(
    'flex',
    'flex-1',
    'justify-center',
    'items-center',
    'min-w-19 ',
    'justify-between',
    'text-sm',
    'text-gray-700',
    'font-normal',
    'capitalize',
  ),
  card: (status: string, expired: string, deliveryExpired: boolean) =>
    classNames(
      moment(expired).isBefore(moment(), 'day') && status !== 'done'
        ? 'bg-red-three'
        : '',
      status === 'done' ? 'bg-green-three' : '',
      deliveryExpired ? 'bg-yellow-two' : '',
      'border-solid',
      'border',
      'text-left',
      'flex',
      'flex-col',
      'relative',
      'rounded',
      'text-xs',
      'hover:bg-grey-light',
      'break-words',
      'cursor-pointer',
      'bg-white',
      'relative',
      'p-2',
    ),
  dateHeader: classNames(
    'text-sm',
    'capitalize',
    'text-gray-500',
    'whitespace-nowrap',
    'font-normal',
  ),
}

interface ICanvasLength {
  canvasTimeStart: number
  canvasTimeEnd: number
}

export interface ICopiedMilestoneData {
  id: number
  date: string
  mainProcess_id?: number
  theme_id?: number
  discipline_id?: number
  responsible_id?: number
}

const MilestoneCardTimeline = () => {
  moment.tz.setDefault('Europe/Oslo')
  moment.locale('nb')

  const [items, setItems] = useState<any[]>([])
  const [groups, setGroups] = useState<any[]>([])
  const defaultTimeStart = moment()
    .startOf('week')
    .add(-2, 'weeks')
    .toDate()
    .getTime()
  const defaultTimeEnd = moment()
    .startOf('week')
    .add(4, 'weeks')
    .toDate()
    .getTime()
  const [loading, setLoading] = useState<boolean>(true)
  const [canvasDuration, setCanvasDuration] = useState<ICanvasLength>({
    canvasTimeStart: defaultTimeStart,
    canvasTimeEnd: defaultTimeEnd,
  })
  const [showWeek, setShowWeek] = useState<boolean>(true)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showKeypointModal, setShowKeypointModal] = useState<boolean>(false)
  const [newKeypoint, setNewKeypoint] = useState<IKeypoint | undefined>(
    undefined,
  )
  const [lock, setLock] = useState(false)
  const [selectedMilestone, setSelectedMilestone] = useState<
    IMilestone | undefined
  >(undefined)
  const [newMilestone, setNewMilestone] = useState<IMilestone>({
    id: 0,
    name: '',
    status: 'open',
    description: '',
    deadline: moment(),
  } as IMilestone)
  const [reloadData, setReloadData] = useState<boolean>(false)
  const [reloadTimeline, setReloadTimeline] = useState<boolean>(false)
  const [createMilestone, setCreateMilestone] = useState<boolean>(false)
  const { addAlert } = useAlert()
  const [heightRatio, setHeightRatio] = useState<number>(1)
  const [showContext, setShowContext] = useState<boolean>(false)
  const [floatLabel, setFloatLabel] = useState<{ left: number; top: number }>({
    left: 0,
    top: 0,
  })
  const copyGroupTimeRef = useRef<any>()
  const [copiedMilestoneData, setCopiedMilestoneData] = useState<
    ICopiedMilestoneData | undefined
  >(undefined)
  const [showCopyMilestoneModal, setShowCopyMilestoneModal] =
    useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const { t } = useTranslation()

  const projectContext = useContext(ProjectContext)
  const selectedItemRef = useRef<any>()

  const itemRef = useRef(items)
  const currentRatiosRef = useRef(1)
  const prevRatioRef = useRef(1)

  useDidMountEffect(() => {
    const { canvasTimeStart, canvasTimeEnd } = canvasDuration
    const allRatio =
      (defaultTimeEnd - defaultTimeStart) / (canvasTimeStart - canvasTimeEnd)
    const ratio = Math.abs(allRatio)
    prevRatioRef.current = currentRatiosRef.current
    currentRatiosRef.current = ratio
    setHeightRatio(ratio > 1 ? 1 : ratio < 0.35 ? 0.35 : ratio)
  }, [canvasDuration])

  useEffect(() => {
    itemRef.current = items
    if (Math.abs(prevRatioRef.current - currentRatiosRef.current) > 2) {
      prevRatioRef.current = currentRatiosRef.current
      setReloadTimeline((n) => !n)
    }
  })

  const getData = async (filters: any) => {
    const id = projectContext.state.currentProject.id
    const filterData = {
      discipline: filters[CanvasFilterType.DISCIPLINES],
      main_process: filters[CanvasFilterType.PROCESSES],
      team: filters[CanvasFilterType.THEMES],
      tag: filters[CanvasFilterType.TAGS],
      behind_schedule: filters[CanvasFilterType.BACK_PLAN],
      responsible: filters[CanvasFilterType.USERS],
      open: filters[CanvasFilterType.OPEN],
    }
    const milestoneData = await getMainprocessMilestoneCanvasData(
      id,
      filterData,
    )

    setItems(milestoneData.items)
    setGroups(milestoneData.groups)
    setLoading(false)
    setReloadTimeline((n) => !n)
    setLoading(false)
  }

  const reload = (dataRefresh: boolean) => {
    setLoading(dataRefresh)
    setReloadData((n) => !n)
  }

  const showAlert = (type: IAlertType, title: string, text: string) => {
    addAlert({ type, title, description: text })
  }

  const copyItem = (item: any) => {
    selectedItemRef.current = item
    showAlert('info', t('the_milestone_is_stored_on_the_clipboard'), '')
  }

  const saveMilestone = async (newMovedMilestone: any) => {
    try {
      await editMilestone(newMovedMilestone)
      showAlert(
        'success',
        t('update_successful'),
        t('milestone_has_been_updated'),
      )
    } catch (error) {
      /**/
    }
    reload(false)
  }

  const onItemMove = (itemId: any, time: any) => {
    const itemCopy = [...itemRef.current]
    const milestone = { id: itemId, deadline: moment.unix(time / 1000) } as any
    const item = itemCopy.find((i) => i.id === itemId)
    const itemIdex = itemCopy.indexOf(item)

    saveMilestone(milestone)

    item['start_time'] = moment
      .unix(time / 1000)
      .startOf('week')
      .add(6, 'hours')
    item['end_time'] = moment
      .unix(time / 1000)
      .endOf('week')
      .subtract(6, 'hours')

    if (itemIdex !== -1) {
      itemCopy[itemIdex] = item
      setItems(itemCopy)
    }
  }

  const contextMenu: IContextMenuItem[] = [
    {
      title: t('paste'),
      active: !!selectedItemRef.current,
      onItemClick: () =>
        openCopyModal(
          copyGroupTimeRef.current.gr,
          copyGroupTimeRef.current.time,
        ),
      disableMessage: t('clipboard_is_empty'),
    },
  ]

  const openCopyModal = (group: any, deadline: number) => {
    const gr = groups.find((g) => g.id === group)
    const processID = Number(gr.id.slice(1))

    const copiedItem = selectedItemRef.current
    const newCopiedKey: ICopiedMilestoneData = {
      date: moment(deadline).toISOString(),
      id: copiedItem.id,
    }

    newCopiedKey.mainProcess_id = processID
    setCopiedMilestoneData(newCopiedKey)
    setShowCopyMilestoneModal(true)
  }

  const showMenu = (gr: any, time: number, e: any) => {
    copyGroupTimeRef.current = { gr, time }
    const tmpFloatLabel = { ...floatLabel }
    tmpFloatLabel.left = e.pageX
    tmpFloatLabel.top = e.pageY
    setShowContext(true)
    setFloatLabel(tmpFloatLabel)
  }

  const onZoom = (timelineContext: any) => {
    const { visibleTimeStart, visibleTimeEnd } = timelineContext
    setCanvasDuration({
      canvasTimeStart: visibleTimeStart,
      canvasTimeEnd: visibleTimeEnd,
    })
  }

  const onEmptyCellClick = (group: number, deadline: number) => {
    const gr = groups.find((g) => g.id === group)
    const processID = Number(gr.id.slice(1))
    const mainProcess = {
      id: processID,
      name: gr.title,
    } as IMainProcess
    const newMile = {
      main_process_id: processID,
      main_process: mainProcess,
      deadline: moment(deadline).toISOString(),
      status: 'open',
      description: '',
    }
    setNewMilestone(newMile as any)
    setCreateMilestone(true)
  }
  const onTimeChange = (
    visibleTimeStart: any,
    visibleTimeEnd: any,
    updateScrollCanvas: any,
  ) => {
    setCanvasDuration({
      canvasTimeStart: visibleTimeStart,
      canvasTimeEnd: visibleTimeEnd,
    })
    updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
  }

  const onCreateKeypoint = (item: IMilestone) => {
    setSelectedMilestone(item)
    const newItem = {
      mile_stone_id: item?.id,
      main_process_id: item.main_process_id,
    } as IKeypoint
    setNewKeypoint(newItem)
    setShowKeypointModal(true)
  }

  const onCloseKeypointModal = () => {
    setSelectedMilestone(undefined)
    setNewKeypoint(undefined)
    setShowKeypointModal(false)
  }

  const itemRenderer = ({
    item,
    itemContext,
    timelineContext,
    getItemProps,
  }: any) => {
    return (
      <MilestoneCardTimelineItem
        getItemProps={getItemProps}
        item={item}
        openMilestone={openMilestone}
        timelineContext={timelineContext}
        itemSelected={itemContext.selected}
        itemContext={itemContext}
        copyItem={copyItem}
        deleteItem={onDeletItemClick}
        updateItem={saveMilestone}
        onCreateKeypoint={onCreateKeypoint}
      />
    )
  }

  const onDeletItemClick = (row: any) => {
    setShowDeleteModal(!showDeleteModal)
    setSelectedMilestone(row)
  }

  const removeMilestone = () => {
    if (selectedMilestone) {
      deleteMilestone(selectedMilestone)
        .then(() => {
          reload(false)
          setShowDeleteModal(false)
          setSelectedMilestone(undefined)
        })
        .catch((err) => console.error(err))
    }
  }

  const openMilestone = (data: any) => {
    setSelectedMilestone(data)
    setShowModal(true)
  }

  const groupRenderer = ({ group }: any) => {
    return (
      <div style={{ height: 'inherit' }} className={styleClass.rowName}>
        <span>
          {group.title &&
            (group.title.length > 25
              ? group.title.substring(0, 25) + '...'
              : group.title)}
        </span>
        <div
          className={group.color}
          style={{ width: '15px', height: '15px', borderRadius: '50%' }}
        />
      </div>
    )
  }

  const resetTimePosition = () => {
    setCanvasDuration({
      canvasTimeStart: defaultTimeStart,
      canvasTimeEnd: defaultTimeEnd,
    })
    setHeightRatio(1)
    setReloadTimeline((n) => !n)
  }

  return (
    <>
      <div>
        {!loading ? (
          <div key={reloadTimeline ? '1' : '2'}>
            <Timeline
              groups={groups}
              items={items}
              itemsSorted={true}
              itemTouchSendsClick={false}
              stackItems={true}
              sidebarWidth={200}
              itemHeightRatio={0.9}
              showCursorLine={true}
              lineHeight={80 * heightRatio}
              groupRenderer={groupRenderer}
              canMove={!lock}
              canResize={false}
              canChangeGroup={false}
              dragSnap={24 * 60 * 60 * 1000}
              minZoom={30 * 86400 * 1000} // A year
              maxZoom={3 * 365.24 * 86400 * 1000} // three Year
              defaultTimeStart={moment(canvasDuration.canvasTimeStart)}
              defaultTimeEnd={moment(canvasDuration.canvasTimeEnd)}
              onCanvasDoubleClick={(groupId: any, time: number) =>
                onEmptyCellClick(groupId, time)
              }
              onCanvasContextMenu={(groupId: any, time: number, e: any) =>
                showMenu(groupId, time, e)
              }
              onZoom={onZoom}
              onTimeChange={onTimeChange}
              onItemMove={onItemMove}
              itemRenderer={itemRenderer}
            >
              <TodayMarker>
                {({ styles }: any) => {
                  const newStyles = {
                    ...styles,
                    backgroundColor: '#FD7171',
                    width: '6px',
                  }
                  return <div style={newStyles} />
                }}
              </TodayMarker>
              <TimelineHeaders
                className={'sticky'}
                style={{ display: 'flex', width: '100%', top: 0 }}
                classNames={styleClass.dateItem}
              >
                <SidebarHeader style={{ width: '200px' }}>
                  {({ getRootProps }: any) => {
                    return (
                      <div {...getRootProps()}>
                        <div
                          className={styleClass.sideHeader}
                          style={{
                            width: '200px',
                            height: '100%',
                            backgroundColor: '#F4F5F7',
                          }}
                        >
                          <div
                            style={{
                              height: '73px',
                              width: '200px',
                              color: '#6b7280',
                            }}
                            className={styleClass.gridItem}
                          >
                            {t('main_process')}
                          </div>
                        </div>
                      </div>
                    )
                  }}
                </SidebarHeader>

                <DateHeader
                  className={styleClass.dateHeader}
                  unit="year"
                  labelFormat="YYYY"
                />
                <CustomMonthHeader setShowWeek={setShowWeek} />
                {showWeek && (
                  <DateHeader
                    className={styleClass.dateHeader}
                    unit="week"
                    labelFormat="w"
                  />
                )}
              </TimelineHeaders>
            </Timeline>
            <ContextMenu
              show={showContext}
              pos={floatLabel}
              menus={contextMenu}
              closeMenu={() => setShowContext(false)}
            />
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <HelperIcons className="top-20 right-4">
        <>
          <LockIcon lock={lock} setLock={setLock} />
          <CanvasFloatingFilter
            showProcessSelector={true}
            showThemes={true}
            showBackPlanFilter={true}
            showUser={true}
            showTag={true}
            showOpenFilter={true}
            filterName={TimelineFilterTypes.MAIN_PROCESS_MAIN_PROCESS}
            getData={getData}
            reload={reloadData}
          />
          <TimeLineResetDate onReset={resetTimePosition} />
        </>
      </HelperIcons>
      {showModal && selectedMilestone && selectedMilestone.id ? (
        <MilestoneInspectorPanel
          milestoneId={selectedMilestone.id}
          origin={{
            name: t('visual_overview_per_main_process'),
            url: '/milestone?tabIndex=1',
          }}
          open={showModal}
          onClose={() => {
            setShowModal(false)
            setSelectedMilestone(undefined)
            reload(false)
          }}
          onUpdate={() => reload(false)}
        />
      ) : null}

      {showCopyMilestoneModal ? (
        <Modal
          show={showCopyMilestoneModal}
          closeModal={() => {
            setShowCopyMilestoneModal(false)
          }}
          title={t('add_milestone')}
          maxWidth={800}
          confirmOutsideClick={true}
        >
          <div>
            <MilestoneForm
              milestone={copiedMilestoneData as any}
              projectId={projectContext.state.currentProject.id}
              copy={true}
              closeModal={() => {
                setShowCopyMilestoneModal(false)
                reload(false)
              }}
            />
          </div>
        </Modal>
      ) : null}

      {createMilestone ? (
        <Modal
          show={createMilestone}
          closeModal={() => {
            setCreateMilestone(false)
          }}
          title={t('add_milestone')}
          maxWidth={800}
          confirmOutsideClick={true}
        >
          <MilestoneForm
            milestone={newMilestone}
            projectId={projectContext.state.currentProject.id}
            closeModal={() => {
              setCreateMilestone(false)
              reload(false)
            }}
          />
        </Modal>
      ) : null}

      {showKeypointModal && selectedMilestone && newKeypoint && (
        <KeypointModal
          show={showKeypointModal}
          createKeypoint={true}
          close={onCloseKeypointModal}
          keypoint={newKeypoint}
          onCreateKeypoint={onCloseKeypointModal}
        />
      )}

      {selectedMilestone && (
        <DeleteModal
          onDelete={removeMilestone}
          show={showDeleteModal}
          closeModal={() => {
            setShowDeleteModal(false)
          }}
          itemIdnType={`${selectedMilestone.record_id} ${t('milestone')}`}
          itemName={`${selectedMilestone.record_id} - ${selectedMilestone.name}`}
        />
      )}
    </>
  )
}

export default MilestoneCardTimeline
